export const buttonMap = require("../assets/button_map.svg")
export const notifyButton = require("../assets/notify.svg")
export const notifyButtonDisable = require("../assets/notify_disable.svg")
export const inStore = require("../assets/in_store.svg")
export const collection = require("../assets/collection.svg")
export const delivery = require("../assets/deliver.svg")
export const inStoreEnabled = require("../assets/in_store_enabled.svg")
export const collectionEnabled = require("../assets/collection_enabled.svg")
export const deliveryEnabled = require("../assets/deliver_enabled.svg")
export const logo = require("../assets/logo.png");
export const dashboardIcon = require("../assets/dashboardIcon.svg");
export const newOrderIcon = require("../assets/newOrderIcon.svg");
export const customerManagementIcon = require("../assets/customerManagementIcon.svg");
export const orderManagementIcon = require("../assets/orderManagementIcon.svg");
export const staffInformationIcon = require("../assets/staffInformationIcon.svg");
export const trackDriverIcon = require("../assets/trackDriverIcon.svg");
export const deliveryAreasIcon = require("../assets/deliveryAreasIcon.svg");
export const statisticsAndReportsIcon = require("../assets/statisticsAndReportsIcon.svg");
export const locationIcon = require("../assets/locationIcon.svg");
export const supportIcon = require("../assets/supportIcon.svg");
export const settingsIcon = require("../assets/settingsIcon.svg");
export const logoutIcon = require("../assets/logoutIcon.svg");
export const arrowUpIcon = require("../assets/arrowUpIcon.svg");
export const deliveryIcon = require("../assets/delivery.png");
export const collectionIcon = require("../assets/collection.png");
export const lockedOut = require("../assets/locked-out.png");
export const success = require("../assets/success.png");
export const kitchenDashboardIcon = require("../assets/kitchen_dashboard.svg");
export const flaggedArea = require("../assets/flagged_area_popup.png");
export const errorIcon = require("../assets/error_message.png");
