import React from "react";
import { z } from "zod";
import { Event } from "react-big-calendar";
import { FormControl, FormControlLabel, FormHelperText, FormLabel, IconButton } from "@mui/material";
import { CloseRounded } from "@material-ui/icons";
import Modal from "../../../Modal";
import DateInput, { TimeInput } from "../../../DateTimePicker";
import CustomGreenCheckbox from "../../../CustomGreenCheckBox.web";
import { ContainedButton, OutlinedButton } from "../../../Button";
import ShiftSchema from "../../../Schemas/ShiftSchema";
import { parseDateTime } from "../../../utils";
import "./AddShift.css";

interface Props {
  update?: Event;
  apiError?: null | string[];
  selectedId?: number;
  open: boolean;
  onClose: () => void;
  submit: (data: any) => void;
};

const AddShift = ({ update, apiError, selectedId, open, onClose, submit }: Props) => {
  const isUpdate = !!update;
  const is24HourFormat = false;
  const [data, setData] = React.useState({
    id: selectedId,
    shift_date: "",
    till_date: "",
    start_time: "",
    end_time: "",
    one_week: false,
  });
  const [errors, setErrors] = React.useState<Record<string, string>>({});

  const handleDateChange = (date: Date | string | null, label: string) => {
    if (date) {
      if (typeof date === "string") {
        let value = date.replace(/\D/g, "");
        if (value.length > 4) value = value.slice(0, 4) + "-" + value.slice(4);
        if (value.length > 7) value = value.slice(0, 7) + "-" + value.slice(7, 9);
        setData({ ...data, [label]: value });
        return value;
    }

      if (date instanceof Date) {
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const formattedDate = `${year}-${month}-${day}`;
        setData({ ...data, [label]: formattedDate });
        return formattedDate;
      };
    };
  };

  const handleTimeChange = (
    date: Date | string | null,
    label: string,
  ) => {
    if (date) {
      if (typeof date === "string") {
        let value = date.replace(/\D/g, "");
        if (value.length >= 4) {
          value = value.slice(0, 2) + ":" + value.slice(2, 4);
        }
        setData({ ...data, [label]: value });
        return value;
      }

      if (date instanceof Date) {
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, "0");
        let formattedTime = "";

        if (!is24HourFormat) {
          const period = hours >= 12 ? "PM" : "AM";
          hours = hours % 12 || 12; // Convert to 12-hour format
          formattedTime = `${String(hours).padStart(2, "0")}:${minutes} ${period}`;
        } else {
          formattedTime = `${String(hours).padStart(2, "0")}:${minutes}`;
        }

        setData({ ...data, [label]: formattedTime });
        return formattedTime;
      }
    }
  };


  React.useEffect(() => {
    if (update) {
      setData({
        id: update.resource.staff_id,
        shift_date: parseDateTime(update.start?.toISOString() ?? "").date,
        till_date: parseDateTime(update.end?.toISOString() ?? "").date,
        start_time: parseDateTime(update.start?.toISOString() ?? "").time,
        end_time: parseDateTime(update.end?.toISOString() ?? "").time,
        one_week: false,
      });
    }
  }, [isUpdate]);

  const handleSubmit = () => {
    const validation = ShiftSchema.safeParse(data);
    const errorMap: Record<string, string> = {};

    if (!validation.success) {
      if (validation.error instanceof z.ZodError) {
        validation.error.errors.forEach((err: any) => {
          errorMap[err.path[0]] = err.message;
        });

        setErrors(errorMap);
        return;
      }
    }

    submit(data);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      maxWidth="sm"
    >
      <div className="add_shift__wrapper">
        <div className="add_shift__header">
          <span className="add_shift__header__title">{isUpdate ? "Update" : "Add"} Staff Shift Timing</span>

          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </div>

        {apiError && (
          <ul className="add_shift__error">
            {apiError.map((error: string, index: number) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        )}

        <div className="add_shift__form">
          <FormControl required>
            <FormLabel classes={{ asterisk: "add_shift__asterisk", root: "add_shift__label" }}>
              Date Applied
            </FormLabel>
            <DateInput
              onChange={(date) => handleDateChange(date, "shift_date")}
              onChangeRaw={(e) => handleDateChange(e.target.value, "shift_date")}
              placeholderText="YYYY-MM-DD"
              value={data.shift_date}
            />
            <FormHelperText
              classes={{ root: "add_shift__helper_text" }}
              error={!!errors["shift_date"]}
            >
              {errors["shift_date"]}
            </FormHelperText>
          </FormControl>

          <FormControl>
            <FormLabel classes={{ asterisk: "add_shift__asterisk", root: "add_shift__label" }}>
              Till Date
            </FormLabel>
            <DateInput
              disabled={data.one_week}
              onChange={(date) => handleDateChange(date, "till_date")}
              onChangeRaw={(e) => handleDateChange(e.target.value, "till_date")}
              placeholderText="YYYY-MM-DD"
              value={data.till_date}
              minDate={new Date(data.shift_date)}
            />
            <FormHelperText
              classes={{ root: "add_shift__helper_text" }}
              error={!!errors["till_date"]}
            >
              {errors["till_date"]}
            </FormHelperText>
          </FormControl>

          <div className="add_shift__shift_timing">
            <span className="add_shift__shift_timing__title">Shift Timing</span>

            <div className="add_shift__shift_timing__grid">
              <FormControl required>
                <FormLabel classes={{ asterisk: "add_shift__asterisk", root: "add_shift__label" }}>
                  Start Time
                </FormLabel>
                <TimeInput
                  preventEdit
                  timeFormat="h:mm aa"
                  onChange={(date) => handleTimeChange(date, "start_time")}
                  placeholderText="HH:MM"
                  value={data.start_time}
                />
                <FormHelperText
                  classes={{ root: "add_shift__helper_text" }}
                  error={!!errors["start_time"]}
                >
                  {errors["start_time"]}
                </FormHelperText>
              </FormControl>

              <FormControl required>
                <FormLabel classes={{ asterisk: "add_shift__asterisk", root: "add_shift__label" }}>
                  End Time
                </FormLabel>
                <TimeInput
                  preventEdit
                  timeFormat="h:mm aa"
                  onChange={(date) => handleTimeChange(date, "end_time")}
                  placeholderText="HH:MM"
                  value={data.end_time}
                />
                <FormHelperText
                  classes={{ root: "add_shift__helper_text" }}
                  error={!!errors["end_time"]}
                >
                  {errors["end_time"]}
                </FormHelperText>
              </FormControl>

              <FormControl>
                <FormControlLabel
                  control={
                    <CustomGreenCheckbox
                      checked={data.one_week}
                      onChange={(e) => setData({ ...data, one_week: e.target.checked })}
                    />
                  }
                  label="Apply this shift to whole week"
                  classes={{ label: "add_shift__shift_timing__title" }}
                />
              </FormControl>
            </div>
          </div>
        </div>

        <div className="add_shift__buttons">
          <OutlinedButton onClick={onClose}>
            Cancel
          </OutlinedButton>
          <ContainedButton onClick={handleSubmit}>
            Submit
          </ContainedButton>
        </div>
      </div>
    </Modal>
  );
};

export default AddShift;
