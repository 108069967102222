import React from "react";
import { Calendar, dateFnsLocalizer, Event, View } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { enGB } from "date-fns/locale";
import addHours from 'date-fns/addHours'
import startOfHour from 'date-fns/startOfHour'
import Toolbar from "./components/Toolbar";
import { Header } from "./components/Month";
import { EventComponent } from "./components/EventComponent";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Calendar.css";

interface Props {
  selectedStaff: number | null;
  selectedRole: string | null;
  error?: {
    message: string;
  };
  recievedView?: View;
  recievedViews?: View[];
  events: Event[];
  updateEvents: (events: Event[]) => void;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
};

const WingosCalendar = React.memo(({ selectedStaff, selectedRole, error, events, recievedView, recievedViews, openShiftPopup }: Props) => {
  const [view, setView] = React.useState<View>("month");
  const [views, setViews] = React.useState<View[]>(["month"]);

  React.useEffect(() => {
    if (recievedView) {
      setView(recievedView);
    }
  }, [recievedView]);

  React.useEffect(() => {
      if (recievedViews) {
        setViews(recievedViews);
      }
  }, [recievedViews]);

  return (
    <Calendar
      defaultView="month"
      view={view}
      views={views}
      onView={(view: View) => setView(view)}
      events={events}
      localizer={localizer}
      className="calendar__view"
      components={{
        toolbar: (props) => <Toolbar
          {...props}
          selectedStaff={selectedStaff}
          selectedRole={selectedRole}
          openAddShift={openShiftPopup}
          error={error}
        />,
        month: { header: Header },
        week: { header: Header },
        day: { header: Header },
        timeSlotWrapper: (props) => <div className="calendar__time_slot_wrapper">{props.children}</div>,
        resourceHeader: (props) => <div>{props.children}</div>,
        event: (props) => <EventComponent {...props} selectedStaff={selectedStaff} selectedRole={selectedRole} activeView={view} openShiftPopup={openShiftPopup} />,
        eventWrapper: (props) => <div>{props.children}</div>,
        eventContainerWrapper: (props) => <div>{props.children}</div>
      }}
    />
  );
});

const locales = {
  'en-US': enGB
}

const endOfHour = (date: Date): Date => addHours(startOfHour(date), 1)
const now = new Date()
const start = endOfHour(now)
const end = addHours(start, 2)

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
})

export default WingosCalendar;
