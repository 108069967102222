import React from "react";
import { Event, EventProps, View } from "react-big-calendar";
import { Popover } from "@mui/material";
import { CloseRounded, EditRounded } from "@material-ui/icons";
import { calculateTimeDifference, getEventColor, isDateBeforeToday, parseDateTime } from "../../../../utils";
import { InfoIcon } from "../../../../../../components/src/Icons";
import { UnstyledIconButton } from "../../../../../../components/src/IconButton";
import "./EventComponent.css";

interface EventComponentProps extends EventProps {
  selectedStaff: number | null;
  selectedRole: string | null;
  activeView: View;
  openShiftPopup: (id: number, selectedRole: string, event?: Event) => void;
}

const EventComponent = (props: EventComponentProps) => {
  const { selectedStaff, selectedRole, activeView, openShiftPopup, event } = props;
  const { start: startDateTime, end: endDateTime, title, resource } = event;
  const start = parseDateTime(startDateTime?.toISOString() ?? "");
  const end = parseDateTime(endDateTime?.toISOString() ?? "");
  const totalHours = calculateTimeDifference(startDateTime ?? "", endDateTime ?? "");

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const status = resource?.status;
  const before = isDateBeforeToday(startDateTime?.toISOString() ?? "");

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  return (
    <div
      className="calendar__event"
      data-view={activeView}
      data-status={status}
    >
      {selectedStaff ? (
        <>
          {before ? (
            <>
              <UnstyledIconButton onClick={handleOpen}>
                <InfoIcon width={16} height={16} />
              </UnstyledIconButton>
              <p className="calendar__event__title">{status.split('_').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}</p>
            </>
          ) : (
            <>
              <UnstyledIconButton onClick={() => selectedRole && openShiftPopup(event.resource.id, selectedRole, event)}>
                <EditRounded fontSize="small" />
              </UnstyledIconButton>
              <p className="calendar__event__timestamp" onClick={handleOpen}>
                {start.time} - {end.time}
              </p>
            </>
          )}
        </>
      ) : (
        <>
          <div
            className="calendar__event__role_marker"
            data-role={getEventColor(resource?.roles?.[0]).role}
          />
          <p className="calendar__event__title">{title}</p>
        </>
      )}
      <Popover
        disableRestoreFocus
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: "event_info__wrapper"
        }}
      >
        <div className="event_info__header">
          <span>{start.date}</span>
          <CloseRounded fontSize="small" style={{ cursor: "pointer" }} onClick={handleClose} />
        </div>
        <div className="event_info__body">
          <div className="event_info__body__title">
            <span className="event_info__title">Scheduled Time</span>
            <span className="event_info__timestamp">{start.time} - {end.time}</span>
          </div>
          <div className="event_info__login_logout">
            <span>Log in -</span>
            <span>Log out -</span>
          </div>
          <div className="event_info__total_hours">
            <span>Total Hours</span>
            <span>{totalHours.hours} hours {totalHours.minutes ? `${totalHours.minutes} minutes` : null}</span>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export { EventComponent };
