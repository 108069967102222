Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.contentType = "application/json";
exports.getMethod = "GET";
exports.postMethod = "POST";
exports.patchMethod = "PATCH";
exports.staffInformationAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff",
  method: "GET"
};
exports.lastStaffIdAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff/last_staff",
  method: "GET"
};
exports.availableRolesAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff/roles",
  method: "GET"
};
exports.availableIdentityTypesAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff/identity_types",
  method: "GET"
};
exports.addStaffAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff",
  method: "POST"
};
exports.updateStaffAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff",
  method: "PUT"
}
exports.staffScheduleAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff_schedule",
};

exports.staffScheduleForAllAPI = {
  endPoint: "bx_block_cfposcustomermanagementfe/staff_schedule/all_staff_with_schedules"
}

exports.staffShiftAPI = {
  getShift: "/schedule_view",
  addShift: "/add_shift",
  updateShift: "/update_shift",
};

exports.mockShifts = [
  {
    id: 901,
    staff_id: "0007-0011",
    name: "Rimru Tempest",
    start_date: "2024-11-01",
    end_date: "2024-11-30",
    shifts: [
      {
        id: 7,
        date: "2024-11-19",
        start_time: "08:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 10,
        date: "2024-11-25",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 11,
        date: "2024-11-26",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
    ],
  },
  {
    id: 909,
    staff_id: "0007-0014",
    name: "Eren K Yeager",
    start_date: "2024-11-01",
    end_date: "2024-11-30",
    shifts: [
      {
        id: 12,
        date: "2024-11-19",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 13,
        date: "2024-11-20",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 14,
        date: "2024-11-21",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 15,
        date: "2024-11-22",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 16,
        date: "2024-11-23",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 17,
        date: "2024-11-24",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 18,
        date: "2024-11-25",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
    ],
  },
  {
    id: 912,
    staff_id: "0007-0015",
    name: "Ryomen Sukuna",
    start_date: "2024-11-01",
    end_date: "2024-11-30",
    shifts: [
      {
        id: 2,
        date: "2024-11-19",
        start_time: "12:00",
        end_time: "18:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 3,
        date: "2024-11-20",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 4,
        date: "2024-11-21",
        start_time: "10:00",
        end_time: "18:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 5,
        date: "2024-11-22",
        start_time: "04:30",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 8,
        date: "2024-11-23",
        start_time: "10:00",
        end_time: "18:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 9,
        date: "2024-11-24",
        start_time: "10:00",
        end_time: "18:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 6,
        date: "2024-11-25",
        start_time: "10:00",
        end_time: "18:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 26,
        date: "2024-11-26",
        start_time: "10:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 68,
        date: "2024-11-27",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 71,
        date: "2024-11-28",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 79,
        date: "2024-11-29",
        start_time: "03:30",
        end_time: "11:30",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
    ],
  },
  {
    id: 906,
    staff_id: "0007-0013",
    name: "Gojo Satoru",
    start_date: "2024-11-01",
    end_date: "2024-11-30",
    shifts: [
      {
        id: 19,
        date: "2024-11-25",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 20,
        date: "2024-11-26",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 21,
        date: "2024-11-27",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 22,
        date: "2024-11-28",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 23,
        date: "2024-11-29",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
      {
        id: 24,
        date: "2024-11-30",
        start_time: "09:00",
        end_time: "17:00",
        staff_working_status: "on_duty",
        time_off_id: null,
      },
    ],
  },
];

exports.mockEvents = [
  {
    allDay: true,
    title: "Project Kickoff",
    start: new Date(2024, 10, 7, 12, 0),
    end: new Date(2024, 10, 7, 12, 0),
    resource: {
      location: "Online",
      description: "Initial project kickoff meeting.",
    },
  },
  {
    allDay: false,
    title: "Design Review",
    start: new Date(2024, 10, 8, 10, 0),
    end: new Date(2024, 10, 8, 11, 0),
    resource: {
      location: "Conference Room B",
      description: "Review design mockups with the team.",
    },
  },
  {
    allDay: false,
    title: "Client Presentation",
    start: new Date(2024, 10, 9, 14, 0),
    end: new Date(2024, 10, 9, 15, 0),
    resource: {
      location: "Client Office",
      description: "Present the project plan to the client.",
    },
  },
  {
    allDay: true,
    title: "Team Building Activity",
    start: new Date(2024, 10, 10),
    end: new Date(2024, 10, 10),
    resource: {
      location: "Outdoor Park",
      description: "Team building activities and games.",
    },
  },
  {
    allDay: false,
    title: "Code Review",
    start: new Date(2024, 10, 11, 16, 0),
    end: new Date(2024, 10, 11, 17, 0),
    resource: {
      location: "Conference Room A",
      description: "Review code changes with the development team.",
    },
  },
  {
    allDay: false,
    title: "Marketing Strategy Meeting",
    start: new Date(2024, 10, 12, 9, 0),
    end: new Date(2024, 10, 12, 10, 0),
    resource: {
      location: "Meeting Room 3",
      description: "Discuss marketing strategies for the new product launch.",
    },
  },
  {
    allDay: true,
    title: "Product Launch",
    start: new Date(2024, 10, 13),
    end: new Date(2024, 10, 13),
    resource: {
      location: "Main Auditorium",
      description: "Official launch of the new product.",
    },
  },
];

exports.shiftErrorKeys = [
  "holiday_errors",
  "store_hours_errors",
  "existing_shift_errors",
  "failed_shifts"
];
