import { z } from "zod";
import { detectTimeFormat, parseTimeToMinutes } from "../utils";

const ShiftSchema = z.object({
  shift_date: z.string().nonempty("Date applied is required"),
  till_date: z.string().optional(),
  start_time: z.string().nonempty("Start time is required"),
  end_time: z.string().nonempty("End time is required"),
  one_week: z.boolean(),
}).refine(data => {
  return !data.till_date || new Date(data.shift_date) <= new Date(data.till_date ?? "")
},
    { message: "Till Date should not be after applied shift", path: ["till_date"] }
).refine(data => {
  const isCorrectFormat = detectTimeFormat(data.start_time) === detectTimeFormat(data.end_time);
  return isCorrectFormat;
}, {
  message: "End time should be the same format as start time",
  path: ["end_time"],
}).refine(data => {
  if (!data.start_time || !data.end_time) return;
  const start = parseTimeToMinutes(data.start_time);
  const end = parseTimeToMinutes(data.end_time);

  return start < end;
}, { message: "End time should be after start time", path: ["end_time"] });

export default ShiftSchema;
