// App.js - WEB
import React, { Component } from "react";
import { ScrollView, View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import './App.css';
import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Share from "../../blocks/share/src/Share";
import Search from "../../blocks/search/src/Search";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import AddressManagement from "../../blocks/addressmanagement/src/AddressManagement.web";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import PrivacyPolicy from "../../blocks/termsconditions/src/PrivacyPolicy.web";
import BetterChicken from "../../blocks/termsconditions/src/BetterChicken.web";
import AllergenInformation from "../../blocks/termsconditions/src/AllergenInformation.web";
import OurStory from "../../blocks/termsconditions/src/OurStory.web";
import HeadOffice from "../../blocks/termsconditions/src/HeadOffice.web";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Sorting from "../../blocks/sorting/src/Sorting";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2.web";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings.web";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Notifications from "../../blocks/notifications/src/Notifications";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import TopNav from "./../../components/src/TopNav";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem.web";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import LoyaltySystem from "../../blocks/loyaltysystem/src/LoyaltySystem";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2.web";
import Maps from "../../blocks/maps/src/Maps";
import Geofence from "../../blocks/geofence/src/Geofence";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import Servicespecificsettingsadmin2 from "../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import LandingPage from "../../components/src/LazyLoadedLandingPage.web"
import CustomForm from "../../components/src/LazyLoadedFranchise.web";
import InteractiveFaqs from "../../components/src/LazyLoadedFAQ.web";
import FaqContactus from "../../blocks/interactivefaqs/src/FaqContactus.web";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web"
import DeleteAccount from "../../blocks/settings2/src/DeleteAccount.web"
import AppFooter from "../../components/src/AppFooter.web";
import Inappgifting from "../../blocks/inappgifting/src/Inappgifting.web";
import FeedbackFormButton from "../../blocks/customform/src/FeedBackFormButton.web"
import GiftCardHistory from "../../blocks/inappgifting/src/GiftCardHistory.web"
import TokenExpirePopup from "../../components/src/TokenExpired/TokenExpirePopup"
import OrderHistory from "../../blocks/ordermanagement/src/OrderHistory.web"
import ManagePayment from "../../blocks/paymentadmin2/src/ManagePayment.web"
import MenuPage from "../../components/src/LazyLoadedMenuPage.web"
import DealPage from "../../components/src/LazyLoadedDealsPage.web"
import StoreLocator from "../../components/src/LazyLoadRestaurantPage.web"
import AboutUs from "../../components/src/LazyLoadedAboutUs.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import StaffInformation from "../../blocks/dashboard/src/dashboards/StaffInformation/StaffInformation.web";
import PosOrderCreation from "../../blocks/dashboard/src/dashboards/PosOrderCreation/PosOrderCreation.web"
import DeliveryAreas from '../../blocks/cfdeliveryareamanagement1/src/Cfdeliveryareamanagement1.web';
import StoreAdminDashboard from "../../blocks/dashboard/src/dashboards/StoreAdminDashboard/StoreAdminDashboard.web";
import PaymentStatus from "../../components/src/ConfirmOrder/PaymentStatus.web";
import ViewStaff from "../../blocks/dashboard/src/dashboards/StaffInformation/ViewStaff/ViewStaff.web";
import ChefDashboard from "../../blocks/dashboard/src/dashboards/ChefDashboard/ChefDashboard.web";
import DriverRole from "../../components/src/Dashboard/DriverRole";
import Print from "../../blocks/print/src/Print.web"

const routeMap = {
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
AboutUs:{
  component:AboutUs,
  path:"/AboutUs"
},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Share:{
 component:Share,
path:"/Share"},
Search:{
 component:Search,
path:"/Search"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
AddressManagement:{
 component:AddressManagement,
path:"/AddressManagement"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
EditAddress:{
  component:AddAddress,
 path:"/EditAddress"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
PrivacyPolicy:{
  component:PrivacyPolicy,
  path:"/PrivacyPolicy"
},
BetterChicken:{
  component:BetterChicken,
  path:"/BetterChicken"
},
AllergenInformation:{
  component:AllergenInformation,
  path:"/AllergenInformation"
},
OurStory:{
  component:OurStory,
  path:"/OurStory"
},
HeadOffice:{
  component:HeadOffice,
  path:"/HeadOffice"
},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/ChangePassword"},
Notificationsettings:{
 component:Notificationsettings,
path:"/Notificationsettings"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/accountregistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
 path:"/CartOrderItem"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/accountlogin"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
LoyaltySystem:{
 component:LoyaltySystem,
path:"/WingoRewards"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
Maps:{
 component:Maps,
path:"/Maps"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
Servicespecificsettingsadmin2:{
 component:Servicespecificsettingsadmin2,
path:"/Servicespecificsettingsadmin2"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},

  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
  component: AlertBlock,
  path: "*/AlertWeb",
  modal: true
  },

  CustomForm: {
    component: CustomForm,
    path: "/Franchise"
  },

  Interactivefaqs: {
    component: InteractiveFaqs,
    path: "/Faqs"
  },

  FaqContactus: {
    component: FaqContactus,
    path: "/FaqContactus"
  },
  MenuPage: {
    component:MenuPage,
    path: "/Menu"
  },

  DealPage:{
    component: DealPage,
    path: "/Deals"
  },

  CustomisableUserProfiles:{
    component: CustomisableUserProfiles,
    path: "/Profile"
  },
  DeleteAccount:{
    component: DeleteAccount,
    path: "/DeleteAccount"
  },
  Inappgifting:{
    component: Inappgifting,
    path: "/GiftCard"
  },
  GiftCardHistory:{
    component: GiftCardHistory,
    path: "/GiftCardOrderHistory"
  },
  SessionExpired: {
    component: TokenExpirePopup,
    path: "*/TokenExpired",
    modal: true
    },
  StoreLocator:{
    component: StoreLocator,
    path: "/Restaurant/:order_type?/:post_code?"
  },
  OrderHistory:{
    component: OrderHistory,
    path: "/OrderHistory"
  },
  ManagePayment:{
    component:ManagePayment,
    path:"/PaymentManagement"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard",
  },
  InStoreManager: {
    component: StoreAdminDashboard,
    path: "/Dashboard/InStoreManager",
  },
  InStoreOperator: {
    component: StoreAdminDashboard,
    path: "/Dashboard/InStoreOperator",
  },
  PosOrderCreation:{
    component: PosOrderCreation,
    path: "/Dashboard/NewOrderCreation"
  },
  DeliveryAreas: {
    component: DeliveryAreas,
    path: "/Dashboard/DeliveryAreas",
  },
  StaffInformation: {
    component: StaffInformation,
    path: "/Dashboard/StaffInformation",
  },
  ViewStaff:{
    component: ViewStaff,
    path: "/Dashboard/ViewStaff/:id"
  },
  RiderDriver: {
    component: DriverRole,
    path: "/Dashboard/RiderDriver",
  },
  PaymentStatus:{
    component:PaymentStatus,
    path:"/payment_status"
  },
  ChefDashboard: {
    component: ChefDashboard,
    path: "/Dashboard/Chef",
  },
  OnlineOrderManager: {
    component: StoreAdminDashboard,
    path: "/Dashboard/OnlineOrderManager",
  },
  Print:{
    component:Print,
    path:"/Print"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Define your state properties here
      userDetails: {},
      isLoggedIn: !!localStorage.getItem('authToken'),
    };
  }

  scrollViewRef = React.createRef();
  prevPathname = null;
  componentDidUpdate() {
    // Scroll to the top when component mounts
    if (this.scrollViewRef.current && location.pathname !== this.prevPathname) {
      this.scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true });
    }

    this.prevPathname = location.pathname;
  }

  setLoggedInUser = (loggedIn) => {
    this.setState({isLoggedIn: loggedIn})
  }

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const path = location.pathname;
    const adminRoutes = /^(\/Dashboard(\/.*)?|\/NewOrderCreation?|\/StaffInformation|\/Rolesandpermissions2)$/;
    const isAdminRoute = adminRoutes.test(path);

    return (
      <View className="view" style={{ height: '100vh', width: '100vw' }}>
        {isAdminRoute || path==="/payment_status" ? null : <TopNav isLoggedIn={this.state.isLoggedIn} cls={`${(path === "/accountlogin" || path === "/ForgotPassword" || path==="/NewPassword" || path==="/accountregistration") ?"": "bg-img"}`} />}
        {path ==='/' &&  <FeedbackFormButton/>}

        <ScrollView ref={this.scrollViewRef} style={{ paddingTop: isAdminRoute ||  path==="/payment_status" ? 0 : 115 }}>
          {WebRoutesGenerator({ routeMap, loggedInUser: this.setLoggedInUser })}
          {isAdminRoute ||  path==="/payment_status" ? null : <AppFooter/>}
        </ScrollView>

        <ModalContainer />
      </View>
    );
  }
}

export default App;
