import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  location:any
  cartdetails: Cart;
  restaurant_name: string;
  addRemoveItems: (item: CartItem, action: string) => void;
  editCartItem: (item: CartItem) => void;
  restaurant_id?:string |null;
  token?:string;
  navigateToComboDeal:(id:string)=>void
  cartsList:(token:string)=>void
  // Customizable Area End
}

// Customizable Area Start
import { Cart, CartItem } from "./../../catalogue/src/MenuPageController";
import { getStorageData } from "../../../framework/src/Utilities";
import { RestaurantList } from "../../../blocks/storelocator/src/StoreLocatorController";
import { ProductSideDetais } from "../../../blocks/ordermanagement/src/OrderHistoryController";
export interface ShoppingCartOrderItem {
  id: string | number;
  type?: string;
  attributes: {
    price: number;
    quantity: number;
    taxable: boolean;
    taxable_value: number;
    other_charges?: number;
    catalogue: {
      data?: object & {
        attributes?: object & {
          name?: string;
        };
      };
    };
  };
}
export interface DeliverInstruction {
  id: string,
  type: string,
  attributes: {
    id: number,
    title: string,
    created_at: string,
    updated_at: string,
    images: {
      url: string,
      content_type: string,
      file_name: string
    }
  }
}
type Timer = ReturnType<typeof setTimeout>;
export interface UpdatedProfileData {
  order_id: number;
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
}

export interface OrderCreatedData{
  id: string;
  type: string;
  attributes: {
      id: number;
      order_number: string;
      amount: null;
      account_id: number;
      promo_code_id: number | null;
      coupon_code_id: number|null;
      promo_code_name: null|string;
      redeem_point: number;
      points_worth: string;
      gift_card_ref_num: string | null;
      gift_card_amount: string;
      gift_card_id: number |null;
      delivery_address_id: number | null;
      sub_total: string;
      total: string;
      status: string;
      notes_to_chef: string;
      order_type: string;
      prepare_immediately: boolean;
      schedule_time: string;
      custom_label: null|string;
      applied_discount: string;
      cancellation_reason: null | string;
      order_date: null |string;
      is_gift: boolean;
      placed_at: null;
      confirmed_at: null;
      in_transit_at: null;
      delivered_at: null;
      cancelled_at: null;
      refunded_at: null;
      source: null;
      shipment_id: null;
      delivery_charges: null;
      tracking_url: null;
      payment_failed_at: null;
      payment_pending_at: null;
      returned_at: null;
      tax_charges: string;
      deliver_by: null;
      tracking_number: null;
      is_error: boolean;
      delivery_error_message: null;
      order_status_id: number;
      is_group: boolean;
      is_availability_checked: boolean;
      shipping_charge: null;
      shipping_discount: null;
      shipping_net_amt: null;
      shipping_total: null;
      total_tax: null;
      created_at: string;
      updated_at: string;
      delivery_addresses: [];
      razorpay_order_id: null;
      charged: null;
      invoice_id: null;
      invoiced: null;
      order_items: [];
      personal_detail: {
          id: number;
          first_name: string;
          last_name: string;
          full_phone_number: string;
          order_id: number;
          created_at: string;
          updated_at: string
      };
      account: {
          id: string;
          type: string;
          string: {
              activated: boolean;
              country_code: null;
              email: string;
              first_name: string;
              full_phone_number: string;
              last_name: string;
              phone_number: null;
              type: string;
              created_at: string;
              updated_at: string;
              device_id: null;
              unique_auth_id: null
          }
      }
  }
}

export interface PromoCodeData{
  id: string;
  type: string;
  attributes: {
      name: string;
      status: string;
      discount_type: string;
      discount: number;
      description: string;
      redeem_limit: number;
      max_discount_amount: number | null;
      min_order_amount: number;
      save_value:string;
  }
}

export interface ShoppingCartOrderCustomer {
  id: string | number;
  type: string;
  attributes: {
    activated?: boolean;
    country_code?: string | null;
    email?: string;
    first_name?: string;
    full_phone_number?: string;
    last_name?: string;
    phone_number?: string | null;
    type?: string;
    created_at?: string;
    updated_at?: string;
  };
}

export interface ShoppingCartOrder {
  id: string | number;
  type: string;
  attributes: {
    status?: string;
    total_fees?: number;
    total_items?: number;
    total_tax?: number;
    customer?: {
      data?: ShoppingCartOrderCustomer | null;
    };
    address?: {
      data?:
        | (object & {
            attributes?: object & {
              name?: string;
            };
          })
        | null;
    };
    order_items?: {
      data?: ShoppingCartOrderItem[] | null;
    };
  };
}

export interface DealDataConversion {
  id: string;
  attributes:{
    restaurant : {
      id:number;
      title: string;
    };
    sub_sub_category:{
      "id": number;
      "sub_category_id": number;
      title: string;
      description:string;
    };
    "on_its_own_price": number;
  }
}

export interface ShoppingCartItemsToOrder {
    id: string;
    type: string;
    attributes: {
        id: number;
        cart_id: number;
        catalogue_id: number;
        spice_level: string;
        choose_your_type: string;
        quantity: number;
        price: number;
        product_name: string;
        images: {
            url: string;
            content_type: string;
            file_name: string
        };
        sides: ProductSideDetais[]
        drinks: ProductSideDetais[];
        nibbles: ProductSideDetais[];
        toppings: ProductSideDetais[],
        wraps_product: ProductSideDetais[],
        allergen_excluded: string,
      }
}

export interface RestaurantFactsAttr {
  close_time: string;
  contact: string;
  created_at: string;
  id: number;
  location: string;
  open_time: string;
  order_type: Array<string>
  postcode: string;
  title: string;
  updated_at: string;
}

export interface RestaurantFacts {
  id: string;
  type: string;
  attributes: RestaurantFactsAttr
}

// Customizable Area End

interface S {
  // Customizable Area Start
  order_id: number;
  catalogue_id: number;
  quantity: number;
  taxable: boolean;
  taxable_value: number;
  token: string;
  orderList: ShoppingCartOrder[];
  orderItems: ShoppingCartOrderItem[];
  isVisible: boolean;
  isRefreshing: boolean;
  id: number;
  name: string;
  description: string;
  price: number;
  currency: string;
  category_id: number;
  created_at: string;
  updated_at: string;
  account_id: number;
  product_quantity: number;
  deal_conversion_popup_open : boolean;
  deal_found_data: {deal:{data:DealDataConversion},message:string, save_value_message:string};
  items_to_shopping_cart: ShoppingCartItemsToOrder[];
  total_price_of_item : number;
  message: string;
  emptyCartPopup: boolean;
  cartItemId: number;
  cartItemToEdit: ShoppingCartItemsToOrder | Object;
  notes_to_chef: string;
  confirm_order: boolean;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  order_id_created: string;
  countryCodeSelected: string;
  phoneValue:string;
  edit_first_name: string;
  edit_last_name: string;
  edit_full_phone_number: string;
  editProfile: boolean;
  restaurantList: RestaurantList[];
  postcode: string | null;
  signInToContinue: boolean;
  restaurant_id:string;
  restaun_fact: RestaurantFacts;
  cart_id:number;
  order_detail: OrderCreatedData|null;
  messageCartItems: string;
  couponAmount: number | string;
  pointsAmount: number |string;
  giftCardAmount: number |string;
  save_value_message: string |null;
  minimum_cart_value_message:string|null;
  deliveryInstruction:number[];
  deliveryInstructionList:DeliverInstruction[];
  restaurantUpdated:boolean;
  subTotal:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ShoppingCartOrdersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getOrdersApiCallId?: string;
  showOrderApiCallId?: string;
  createOrderItemApiCallId?: string;
  deleteOrderItemApiCallId?: string;
  getDealsItemApiCallId: string = "";
  deleteAllItemsApiCallId: string = "";
  cartCallId: string = ""
  createOrderApiCallId: string=""
  updateCartItem:string=""
  private debounceTimer: Timer | null = null;
  deleteCartItem : string ="";
  updateProfileApiCallId:string="";
  getProfileApiCallId:string="";
  getRestaurantListId:string="";
  setRestaurantDataToCatalogueId:string="";
  getRewarPointApiCallId:string="";
  getRestaurantDetailsApiCallId:string="";
  getDeliveryInstructionListApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      order_id: 0,
      catalogue_id: 0,
      quantity: 0,
      taxable: false,
      taxable_value: 0,
      token: "",
      orderList: [],
      orderItems: [],
      isVisible: false,
      isRefreshing: false,
      id: 0,
      name: "",
      description: "",
      price: 0,
      currency: "",
      category_id: 0,
      created_at: "",
      updated_at: "",
      account_id: 0,
      product_quantity: 0,
      deal_conversion_popup_open: false,
      deal_found_data:
      {deal:{data:{ id: "",
      attributes:{
        restaurant : {
          id:0,
          title: "",
        },
        sub_sub_category:{
          "id": 0,
          "sub_category_id": 0,
          title: "",
          description: "",
        },
        "on_its_own_price": 0,
      }}},message:"",save_value_message:""},
      items_to_shopping_cart : [
        {
          id: "1",
          type: "menu",
          attributes: {
              id: 1,
              cart_id: 3,
              catalogue_id: 4,
              spice_level: "spice",
              choose_your_type: "type",
              quantity: 2,
              price: 2.33,
              product_name: "product",
              images: {
                  url: "url",
                  content_type: "image",
                  file_name: "image"
              },
              sides: [{catalogue_id:1,name:"sides"}],
              drinks: [{catalogue_id:1,name:"drinks"}],
              nibbles: [{catalogue_id:1,name:"nibbles"}],
              toppings: [{catalogue_id:1,name:"toppings"}],
              wraps_product: [{catalogue_id:1,name:"wrap"}],
              allergen_excluded :""
            }
      }
      ],
      total_price_of_item : 0,
      message: "",
      emptyCartPopup: false,
      cartItemId: 0,
      cartItemToEdit:{},
      notes_to_chef:"",
      confirm_order: false,
      first_name: "",
      last_name: "",
      full_phone_number: "",
      order_id_created: "",
      countryCodeSelected:"",
      phoneValue:"",
      edit_first_name: "",
      edit_last_name: "",
      edit_full_phone_number: "",
      editProfile:false,
      restaurantList:[],
      postcode: null,
      signInToContinue: false,
      restaurant_id:"",
      restaun_fact: {
        id: "",
        type: "",
        attributes: {
          close_time: "",
          contact: "",
          created_at: "",
          id: 0,
          location: "",
          open_time: "",
          order_type: [],
          postcode: "",
          title: "",
          updated_at: ""
          }
        },
      cart_id:0,
      order_detail:null,
      messageCartItems:"",
      couponAmount: 0,
      pointsAmount: 0,
      giftCardAmount: 0,
      save_value_message: "",
      minimum_cart_value_message:null,
      deliveryInstruction:[],
      deliveryInstructionList:[],
      restaurantUpdated:false,
      subTotal:"",
      // Customizable Area End
    };

    // Customizable Area Start
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.getAllRestaurantList()
    this.getDeliveryInstructionList()
    // Customizable Area End
  }

  receive = async (from: String, message: Message) => {
    // Customizable Area Start
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.showOrderApiCallId != null &&
      this.showOrderApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.setState({ isRefreshing: false });

      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          orderItems: responseJson?.data?.attributes?.order_items?.data,
        });
      } else {
        this.setState({ orderItems: [] });

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if(this.getDealsItemApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))){
        this.dealComboFunction(responseJson)
    }
    this.restReceiveFunction(message)

    // Customizable Area End
  };

  // Customizable Area Start
  // start here
  // end here
  componentDidUpdate= async(prevProps:Props, prevState: S)=>{
    if(this.state.items_to_shopping_cart !== prevState.items_to_shopping_cart){
      if(this.state.items_to_shopping_cart?.length< 1 && this.props.location ==="/CartOrderItem" )
      this.props.navigation.navigate("MenuPage")
    }
    if(this.state.order_id_created !== prevState.order_id_created){
      this.getProfileOrder()
    }
    if(this.state.postcode !== prevState.postcode){
      this.getAllRestaurantList()
    }
    if(this.state.total_price_of_item !== prevState.total_price_of_item){
      this.updateMessage(`${parseFloat(Number(this.state.total_price_of_item).toFixed(2))-(Number(this.state.couponAmount)+Number(this.state.pointsAmount)+Number(this.state.giftCardAmount))}`)
    }if(this.state.couponAmount !== prevState.couponAmount || this.state.pointsAmount !== prevState.pointsAmount || this.state.giftCardAmount !== prevState.giftCardAmount){
      this.updateMessage(`${parseFloat(Number(this.state.total_price_of_item).toFixed(2))-(Number(this.state.couponAmount)+Number(this.state.pointsAmount)+Number(this.state.giftCardAmount))}`)
    } if(this.state.restaurantUpdated && this.state.restaurantUpdated !== prevState.restaurantUpdated){
      this.getRestaurantDataToCatalogue(this.state.token)
    } if(this.state.giftCardAmount !== prevState.giftCardAmount || this.state.pointsAmount !== prevState.pointsAmount || this.state.couponAmount !== prevState.couponAmount){
      this.cartsList(localStorage.getItem("authToken")||"")
    }
  }
  restReceiveFunction=(message:Message)=>{
  let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
  const restApiResponse = getName(MessageEnum.RestAPIResponceMessage);
  const cartResponse = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
  this.deletecartItemResponse(apiRequestCallId, message.id, cartResponse,restApiResponse)
    if(this.cartCallId === apiRequestCallId){
      this.setShoppingCartData(responseJson)
    }
    if(this.createOrderApiCallId === apiRequestCallId){
      this.setState({confirm_order:true, order_id_created: responseJson.data.id, order_detail:responseJson.data})
      this.setGiftCardCouponPointsAmount(responseJson.data.attributes.applied_discount,`${responseJson.data.attributes.points_worth}`,responseJson.data.attributes.gift_card_amount)
    }
    if(this.updateProfileApiCallId === apiRequestCallId){
      this.setUpdatedProfileData(responseJson)
    }
    if(this.getProfileApiCallId === apiRequestCallId){
      this.getUpdatedProfileData(responseJson)
    }
    if(this.getRestaurantListId === apiRequestCallId){
      this.setRestaurantList(responseJson)
    }
    if(this.setRestaurantDataToCatalogueId === apiRequestCallId){
      this.setState({restaurant_id:responseJson.data?.id, restaun_fact: responseJson.data});
    }
    if(this.getRewarPointApiCallId === apiRequestCallId){
      this.setState({messageCartItems:responseJson.data.message})
    }
    if(this.updateCartItem === apiRequestCallId){
      this.setState({save_value_message: responseJson.save_value_message, minimum_cart_value_message:responseJson.minimum_cart_value_message })
    }
    if(this.getDeliveryInstructionListApiCallId === apiRequestCallId){
      this.setState({deliveryInstructionList:responseJson.data})
    }
  }

  setUpdatedProfileData=(responseJson:{message:string,data:UpdatedProfileData})=>{
    const {first_name,last_name,full_phone_number} = responseJson.data
    this.setState({first_name,last_name,full_phone_number,editProfile:false})
  }
  getUpdatedProfileData=(responseJson:{message:string,data:UpdatedProfileData,errors?:[{token:string}]})=>{
    if(responseJson.errors){
      this.showAlert("token","token")
    }else{
    const {first_name,last_name,full_phone_number, order_id} = responseJson.data
    this.setState({first_name,last_name,full_phone_number:full_phone_number?`+${full_phone_number}`:"", order_id: order_id})
    }
  }
  setRestaurantList=(responseJson:{data?:RestaurantList[], errors?:string})=>{
    if(responseJson.errors){
      this.setState({restaurantList:[]})
    }else if(responseJson.data){
      this.setState({restaurantList:responseJson.data})
    }
  }
  isNumberNull(number_value?: number) {
    return (
      number_value === null ||
      Number.isNaN(number_value) ||
      number_value === undefined
    );
  }
  setShoppingCartData=(responseJson:{data:ShoppingCartItemsToOrder[], total_price: number,save_value_message: string | null,minimum_cart_value_message:string|null ,message: string, errors?:[{token:string}],order?:any})=>{
    if(responseJson.errors){
      this.showAlert('token','token')
    }else{
    this.setState({items_to_shopping_cart: responseJson.data, message:responseJson.message,save_value_message: responseJson.save_value_message ,total_price_of_item:parseFloat(responseJson.total_price.toFixed(2)), cart_id:responseJson.data[0]?.attributes.cart_id,messageCartItems:responseJson.message,minimum_cart_value_message:responseJson.minimum_cart_value_message})
    if(responseJson.order){
      this.setState({subTotal:responseJson.order.sub_total,couponAmount:responseJson.order.coupons, pointsAmount:responseJson.order.redeem_points, giftCardAmount:responseJson.order.gift_card, total_price_of_item:responseJson.order.total_price})
    }
    }
  }

  updateMessage=(amount:string)=>{
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRewarPointApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getRewardPointApiEndPoint}${amount}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

itemsList=(data: any[], id: number, fav: boolean) => {
    data.forEach(mainItem => {
      mainItem.sub_sub_categories.forEach((subItem: any) => {
        if (subItem.id === id) {
          subItem.favourited = fav;
        }
      });
    });
    return data;
  }

  dealComboFunction = (responseJson:{deal?:{data:DealDataConversion},message:string,save_value_message:string})=>{
    if(responseJson.deal && responseJson.deal.data ){
      this.setState({deal_found_data:{deal:{data:responseJson.deal.data},message:responseJson.message,save_value_message:responseJson.save_value_message}})
      this.openDealConversionPopup()
    } else {
      this.navigateToAddShoppingCartOrderItem()
    }
  }
  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  navigateToAddShoppingCartOrderItem = () => {
    this.props.navigation.navigate("AddShoppingCartOrderItem");
  };

  navigateToShoppingCartOrders = () => {
    this.props.navigation.navigate("ShoppingCartOrders");
  };

  getToken = () => {
      getStorageData('authToken',false).then(data => {
         this.setState({token: data,})
          this.getShoppingCartList(data)
          this.getRestaurantDataToCatalogue(data)
         return data
       })
  };

  setModal = (item: {
    post: {
      id: number;
      name: string;
      description: string;
      price: number;
      currency: string;
      category_id: number;
      created_at: string;
      updated_at: string;
      account_id: number;
    };
  }) => {
    this.setState({
      id: item.post?.id,
      name: item.post?.name,
      description: item.post?.description,
      price: item.post?.price,
      currency: item.post?.currency,
      category_id: item.post?.category_id,
      created_at: item.post.created_at,
      updated_at: item.post?.updated_at,
      account_id: item.post?.account_id,
      isVisible: !this.state.isVisible,
    });
  };

  getOrders = (token: string) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const params = { filter_by: "scheduled" };

    this.getOrdersApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(params).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrdersApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showOrder = (order_id: S["order_id"]) => {
    this.setState({ isVisible: true });

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.showOrderApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getOrdersApiEndPoint + "/" + `${order_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.setState({ isRefreshing: true });
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createOrderItem = (token: string) => {
    if (
      this.isNumberNull(this.state.catalogue_id) ||
      this.isNumberNull(this.state.quantity) ||
      this.isNumberNull(this.state.taxable_value)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory,
        ""
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.apiContentType,
      token: token,
    };
    const order_items = {
      catalogue_id: this.state.catalogue_id,
      quantity: this.state.quantity,
      taxable: this.state.taxable,
      taxable_value: this.state.taxable_value,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.createOrderItemApiCallId = requestMessage.messageId;

    const httpBody = {
      order_items,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  };

  deleteOrderItem = (
    order_id: string | number,
    order_item_id: string | number
  ) => {
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteOrderItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteOrderItemApiEndPoint +
        "/" +
        `${order_id}` +
        "/order_items/" +
        `${order_item_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  setCatalogueId = (catalogue_id?: string) => {
    this.setState({ catalogue_id: Number(catalogue_id) || 0 });
  };

  setQuantity = (quantity?: string) => {
    this.setState({ quantity: Number(quantity) || 0 });
  };

  setTaxable = (taxable?: boolean) => {
    this.setState({ taxable: taxable || false });
  };

  setTaxableValue = (taxable_value?: string) => {
    this.setState({ taxable_value: Number(taxable_value) || 0 });
  };

  setVisible = (isVisible: boolean) => {
    this.setState({ isVisible: isVisible });
  };

  addOrderItem = () => {
    this.setState({ isVisible: false });
    this.navigateToAddShoppingCartOrderItem();
  };

  showOrderItems = (order_id: string | number) => {
    const order_id_num = Number(order_id) || 0;
    this.setState({ order_id: order_id_num });
    this.showOrder(order_id_num);
  };

  checkout=()=>{
    if(!localStorage.getItem('authToken')){
      this.setState({signInToContinue:true})
    } else{
      this.getDealsonAddedItem()
    }
  }

  closeSignInToContinue=()=>{
    this.setState({signInToContinue:false})
  }

  getDealsonAddedItem =() =>{
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.props.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDealsItemApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.comboDealsApiEndPoint}restaurant_id=${this.props.restaurant_id}&cart_id=${this.props.cartdetails.data[0].attributes.cart_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getRestaurantDetails =() =>{
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.props.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRestaurantDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getRestaurantDetailsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openDealConversionPopup=()=>{
    this.setState({deal_conversion_popup_open:true})
  }
  closeDealConversionPopup=()=>{
    this.setState({deal_conversion_popup_open:false})
  }
  removeAllItemsFromCart=()=>{
    const header = {
      "Content-Type": configJSON.apiContentType,
      token: this.props.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAllItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.removeAllItemApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deletecartItemResponse(callId: string, id: string, cartResponse: any, restApiResponse: string) {
    if (restApiResponse === id &&
    this.deleteCartItem !== null &&
    this.deleteCartItem === callId) {
    const cartData = this.state.items_to_shopping_cart
    const updatedItems = cartData.filter((cartItem) => cartItem.attributes.id !== this.state.cartItemId);
    const deletedCartItem : ShoppingCartItemsToOrder[] = cartData.filter((cartItem) => cartItem.attributes.id === this.state.cartItemId);
    const price = (deletedCartItem[0].attributes.quantity * deletedCartItem[0].attributes.price)
    const newPrice = (this.state.total_price_of_item - price)
    const CorrectPrice = parseFloat(newPrice.toFixed(2));
    this.setState({items_to_shopping_cart: updatedItems, emptyCartPopup: false, total_price_of_item: CorrectPrice})
  }
  }

  getShoppingCartList =(token:string)=>{
    if(this.props.location === "/CartOrderItem"){
      this.cartsList(token)
    }
  }

  cartsList = async(token: string)=> {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cartCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cartsList
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  createOrder = async()=> {
    const {token, notes_to_chef} = this.state
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };
    const httpBody = {
      "order": {
        notes_to_chef
    }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createOrderApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.postApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateItem(params: {id: number, catalogue_id: number, quantity: number, price?: number, spice_level?: string, choose_your_type?: string, cart_item_sides_attributes?: any[]}) {
    const {id, catalogue_id, quantity, spice_level, choose_your_type, cart_item_sides_attributes, price} = params;
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };

    const httpBody = {
      cart: { catalogue_id, quantity, spice_level, choose_your_type, sides: cart_item_sides_attributes, price}
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateCartItem = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cartsList + "/" + `${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  editCartItem = (item: ShoppingCartItemsToOrder) => {
    this.navigateToMenuDescription(String(item.attributes.catalogue_id),item, item.id);
  }
  navigateToMenuDescription = (catalogue_id:string,item:ShoppingCartItemsToOrder, id: string  = '')=>{
    const message: Message = new Message(
      getName(MessageEnum.NavigationMenuMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage),{catalogue_id:catalogue_id,id:id,item:item})
    this.send(message);
  }
  deleteSpecificItem = (id:number)=>{
    this.setState({emptyCartPopup: true, cartItemId: id});
  }
  deleteCart = () => {
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCartItem = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cartsList + "/" + `${this.state.cartItemId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  closeEmptyCart = () => {
    this.setState({emptyCartPopup: false, cartItemId: 0});
  }
  itemInTimeout(cartData: ShoppingCartItemsToOrder[], idx: number, item: ShoppingCartItemsToOrder) {
    const {id, catalogue_id, quantity} = cartData[idx].attributes;
      this.updateItem({id, catalogue_id, quantity})
  }
  incDecItemCount(cartData: ShoppingCartItemsToOrder[], idx: number, item: ShoppingCartItemsToOrder, action: string) {
    const priceToUpdate = cartData[idx].attributes.price
    action === 'addItem' ? cartData[idx].attributes.quantity++ : cartData[idx].attributes.quantity--;
    this.setState((prevState) => ({items_to_shopping_cart: cartData, total_price_of_item: action === 'addItem'
    ? prevState.total_price_of_item + parseFloat(priceToUpdate.toFixed(2))
    : prevState.total_price_of_item - parseFloat(priceToUpdate.toFixed(2))}));
    this.debounceTimer = setTimeout(() => {
      this.itemInTimeout(cartData, idx, item)
    }, 1000);
  }

  addRemoveCartItems(item: ShoppingCartItemsToOrder, action: string | null) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    const cartData = this.state.items_to_shopping_cart
    const idx = cartData.findIndex((cartItem) => (cartItem.id || cartItem.attributes.catalogue_id) === (item.id || item.attributes.catalogue_id));
    if(action === 'addItem') {
     this.incDecItemCount(cartData, idx, item, action)
    } else if(action === 'removeItem') {
      if(cartData[idx].attributes.quantity === 1) {
        //raise a popup to ask if he/she wants to remove popup or not
        this.setState({emptyCartPopup: true, cartItemId: cartData[idx].attributes.id});
      } else {
       this.incDecItemCount(cartData, idx, item, action)
      }
    }
  }
  handleNotesToChef = (value:string)=>{
    this.setState({notes_to_chef:value})
  }
  navigateToReviewOrder=()=>{
    this.setState({confirm_order:false})
  }
  updateProfileOrder =()=>{
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileApiEndPoint + "/" + `${this.state.order_id_created}/edit_personal_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    const httpBody = {
      "personal_detail":{
        "first_name": this.state.edit_first_name,
        "last_name": this.state.edit_last_name,
        "full_phone_number":  this.state.edit_full_phone_number
    }
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getProfileOrder =()=>{
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": this.state.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateProfileApiEndPoint + "/" + `${this.state.order_id_created}/get_personal_details`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleInputChangeFirstName =(value: string)=>{
    this.setState({edit_first_name: value})
  }
  handleInputChangeLastName =(value: string)=>{
    this.setState({edit_last_name: value})
  }
  handleCountryCodeChange = (value: string): void => {
    this.setState({countryCodeSelected : value })
  }
  handleMobileChange = (value: string): void => {
    const edit_full_phone_number = `+${value}`
    this.setState({edit_full_phone_number})
  }
  editProfileOpenClose=()=>{
    this.setState({editProfile:!this.state.editProfile, edit_first_name: this.state.first_name, edit_last_name: this.state.last_name, edit_full_phone_number: this.state.full_phone_number})
  }
  getAllRestaurantList=():void=>{
    const header = {
      "Content-Type": configJSON.apiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRestaurantListId = requestMessage.messageId

    const params= {
      postcode: this.state.postcode
    }
    let queryParams = []
    if (params.postcode) {
      queryParams.push(`postcode=${params.postcode}`);
    }
    let restaurantListApiEndPoint = configJSON.restaurantListApi
    if (queryParams.length > 0) {
      restaurantListApiEndPoint += '?' + queryParams.join('&');
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      restaurantListApiEndPoint,
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  navigateToMenuPage=()=>{
    this.props.navigation.navigate("MenuPage")
  }
  navigationToSignIn=()=>{
    this.props.navigation.navigate('EmailAccountLoginBlock')
  }
  getRestaurantDataToCatalogue=(token:string)=>{
    const headers = {
      "Content-Type": configJSON.apiContentType,
      "token": token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setRestaurantDataToCatalogueId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setRestaurantApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setOrderDetails=(coupon:number|null)=>{
      const {order_detail} = this.state
      this.setState({order_detail: order_detail?{...order_detail,attributes:{...order_detail.attributes, promo_code_id:coupon}}:null})
  }
  setGiftCardCouponPointsAmount=(coupons:string,redeem_points:string,gift_card:string)=>{
    this.setState({giftCardAmount:Number(gift_card),couponAmount:Number(coupons),pointsAmount:Number(redeem_points)})
  }
  showMinimumCartMessage=(message:string)=>{
    this.setState({minimum_cart_value_message:message})
  }

  getDeliveryInstructionList=()=>{
    const headers = {
      "Content-Type": configJSON.apiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDeliveryInstructionListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDeliveryInstructionApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  changeDeliveryInstruction = (instruction:number) => {
    this.setState((prevState) => {
      let deliveryInstruction: number[] = prevState.deliveryInstruction || [];

      deliveryInstruction = deliveryInstruction.includes(instruction) ?
      deliveryInstruction.filter(id => id !== instruction) :
        [...deliveryInstruction, instruction];



      return { deliveryInstruction };
    });
  };

  setChangeDeliveryInstructions=(instructions:number[])=>{
    this.setState({deliveryInstruction:instructions})
  }

  setOrderTypeChange=(orderType:string)=>{
    this.setState({token:orderType})
  }

  setOrderTypeEmpty=()=>{
    this.setState({orderList:[]})
  }

  isRestaurantChanged=(value:boolean)=>{
    this.setState({restaurantUpdated:value})
  }
  // Customizable Area End
}
